import { cn } from "@/utils/utils";
import React from "react";
export default function ProductCardName({
	name,
	className,
}: {
	name: string;
	className?: string;
}) {
	return (
		<p
			className={cn(
				"text-gray-500 h-[26px] leading-[13px] md:h-[38px] md:leading-[1.6] capitalize text-[12px] md:text-sm hover:text-yellow-500 hover:underline transition-all duration-300 cursor-pointer line-clamp-2",
				className
			)}>
			{name}
		</p>
	);
}
