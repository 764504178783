import React from "react";
import { cn } from "@/utils/utils";

import Website from "@/common/constants/webstite";
import { ProductBrandJson } from "@/types/Product.type";

import LinkElement from "@/components/LinkElement";

export default function ProductCardBrand({
  brand,
  className,
}: {
  brand: ProductBrandJson | null;
  className?: string;
}) {
  return (
    <LinkElement
      href={
        brand?.handle && brand?.handle?.length > 0
          ? `  ${Website.LINK.PAGE.BLOG}/thuong-hieu-nuoc-hoa/${brand?.handle}`
          : "/collections/all"
      }
      className={cn(
        "text-sm hover:text-red-500 font-bold text-gray-500 uppercase text-left h-[19px] leading-[1.6] line-clamp-1",
        className
      )}
    >
      {brand && brand.title}
    </LinkElement>
  );
}
