import LinkElement from '@/components/LinkElement'
import React from 'react'

export default function ProductCardLink({ children , handle }: { children: React.ReactNode  , handle : string}) {
  return (
    <LinkElement  href={`/products/${handle}`}>
      {children}
    </LinkElement>
  )
}
