"use client";
import { ProductJson } from "@/types/Product.type";
import React, { useMemo } from "react";
import Money from "@/components/ui/Money";
import { PricePromotionJson } from "@/types/Cart.type";
import useTimeServer from "@/lib/hooks/useTimeServer";

function ProductCardPrice({
	product,
	pricePreview,
}: {
	product: ProductJson;
	pricePreview?: PricePromotionJson | null;
}) {
	const isGroup =
		product.price_min > 0 &&
		product.price_max > 0 &&
		product.price_max > product.price_min &&
		product.parent_id === 0;

	const elSingle = useMemo(() => {
		let result = <></>;
		if (pricePreview && pricePreview.discount > 0) {
			result = (
				<>
					<Money className="font-bold" value={pricePreview.price_final} />
					{product.compare_at_price > 0 &&
					product.compare_at_price > product.price ? (
						<>
							<span>-</span>
							<Money
								className="line-through text-gray-400  font-normal"
								value={product.compare_at_price}
							/>
						</>
					) : (
						<>
							<span>-</span>
							<Money
								className="line-through text-gray-400  font-normal"
								value={product.price}
							/>
						</>
					)}
				</>
			);
		} else {
			result = (
				<>
					<Money className="font-bold" value={product.price} />
					{product.compare_at_price > 0 &&
						product.compare_at_price > product.price && (
							<>
								{/* <span>-</span> */}
								<Money
									className="line-through text-gray-400 font-normal ml-1"
									value={product.compare_at_price}
								/>
							</>
						)}
				</>
			);
		}
		return result;
	}, [pricePreview, product]);
	return (
		<p className="leading-[1.5] w-full text-sm md:text-sm text-red-500 font-bold line-clamp-2 flex flex-wrap gap-[2px]">
			{isGroup ? (
				<>
					<Money className="font-bold" value={product.price_min} />
					<span>-</span>
					<Money className="font-bold" value={product.price_max} />
				</>
			) : (
				<>
					{elSingle}
					{/* <span>
						{pricePreview && pricePreview.discount > 0 ? (
							<Money value={pricePreview.price_final} />
						) : (
							<Money value={product.price} />
						)}
					</span>

					<span>-</span>
					<span className="line-through text-gray-400  font-normal">
						{product.compare_at_price > 0 &&
						product.compare_at_price > product.price ? (
							<Money value={product.compare_at_price} />
						) : (
							<Money value={product.price} />
						)}
					</span> */}
				</>
			)}
		</p>
	);
}

export default ProductCardPrice;
