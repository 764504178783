"use client";

import React from "react";
import { cn } from "@/utils/utils";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { LoadingIcon } from "@/components/icons";

export default function LoadingTinySkeleton({
  text = "Đang tải...",
  className,
}: {
  text?: string;
  className?: string;
}) {
  const { t } = useTranslationApi(["loading"]);
  return (
    <div
      className={cn("flex items-center justify-center gap-2 h-28", className)}
    >
      <LoadingIcon /> {t("loading")}
      {/* <span>{text}</span> */}
    </div>
  );
}
