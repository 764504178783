"use client";
import { ProductTagJson } from "@/types/Product.type";
import { cn } from "@/utils/utils";
type Props = {
	tag: ProductTagJson;
	className?: string;
};
export default function ProductCardTag({ tag, className }: Props) {
	return <span className={cn("", className)}>{tag.name}</span>;
}
