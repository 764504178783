import React from "react";
import { cn } from "@/utils/utils";
function AddCircleRed({ className }: { className?: string }) {
  return (
    <span className={cn("  inline-block w-6 h-6 ", className)}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Essential ">
          <path
            id="Vector"
            d="M12 2.5C6.49 2.5 2 6.99 2 12.5C2 18.01 6.49 22.5 12 22.5C17.51 22.5 22 18.01 22 12.5C22 6.99 17.51 2.5 12 2.5ZM16 13.25H12.75V16.5C12.75 16.91 12.41 17.25 12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V13.25H8C7.59 13.25 7.25 12.91 7.25 12.5C7.25 12.09 7.59 11.75 8 11.75H11.25V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V11.75H16C16.41 11.75 16.75 12.09 16.75 12.5C16.75 12.91 16.41 13.25 16 13.25Z"
            fill="#D72229"
          />
        </g>
      </svg>
    </span>
  );
}

export default AddCircleRed;
