import React from "react";
type Props<T> = {
	data: Array<T>;
	render: (item: T, index: number) => React.ReactNode;
};
export default function MapElements<T>({
	data,
	render,
}: Props<T>): React.ReactElement {
	if (typeof data !== "object") {
		return <></>;
	}
	return (
		<>
			{data.length > 0 &&
				data.map((i, index) => (
					<React.Fragment key={index}>{render(i, index)}</React.Fragment>
				))}
		</>
	);
}
