"use client";
import React, { useEffect, useMemo, useState } from "react";

import ShoppingBtn from "~components/cart/ShoppingBtn";
import ProductPopupLoading from "./ProductPopupLoading";
import LoadingElement from "@/components/LoadingElement";
import ProductTags from "~components/product/ProductTags";
import ProductVariantGroup from "../../product/ProductVariantGroup";
import { ProductJson, ProductTagJson } from "@/types/Product.type";
import LinkElement from "@/components/LinkElement";
import ProductTester from "@/app/products/[...handle]/_components/productDetail/_components/productTester/ProductTester";
import { ProductCardTag } from "~components/product/productCardchildren";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import useVariantsV2 from "@/lib/hooks/useVariantsV2";
import ProductRating from "~components/product/productRating/ProductRating";
import ProductSinglePrice from "~components/product/ProductSinglePrice";
import ImageV2 from "@/components/ui/ImageV2";
import useStoreId from "@/lib/hooks/useStoreId";
import Skeleton from "@/components/Skeleton";
import useDealthom from "@/lib/hooks/useDealthom";
import { useAppSelector } from "@/redux/hook";

const translate = {
  brand: "brand",
  view_detail: "view_detail",
  free_ship: "free_ship",
  all_order: "all_order",
  product_sku: "product_sku",
  product_save: "product_save",
};

export default function ProductPopup({ product, handleAddCartSuccecss, onCLose }: {
  product: ProductJson,
  handleAddCartSuccecss?: () => void
  onCLose?: () => void
}) {
  const { t } = useTranslationApi(Object.values(translate));

  const {
    isLoading,
    variantFromTags,
    variantActive,
    handleActiveVariant,
    error,
    refetch,
  } = useVariantsV2({ product: product });
  //state
  const [quantity, setQuantity] = useState<number>(1);
  // const [error, setError] = useState<string[]>([]);
  const { data: store_id } = useStoreId();

  const productSendMail = useAppSelector(state => state.ShopReducer.productSendMail)


  const handleQuantity = ({ type }: { type: "minus" | "add" }) => {
    if (type === "minus") {
      setQuantity(quantity - 1);
    } else if (type === "add") {
      setQuantity(quantity + 1);
    }
  };

  const handlePopupActiveVariant = (variant: ProductJson) => {
    if (quantity > variant.quantity && variant.quantity > 0) {
      setQuantity(variant.quantity);
    }
    handleActiveVariant(variant);
  };

  const listTag = useMemo(() => {
    let result: ProductTagJson[] = [];
    if (product.tags.length > 0) {
      // const tagOnly = product.tags.find((t) => t.code === "only");
      const listTagCard = product.tags.filter(
        (tag) => tag.type === "PRODUCT CARD"
      );
      // if (tagOnly) {
      // 	result.push(tagOnly);
      // }
      result.push(...listTagCard);
      // result.push(...listTagCard)
    }
    if (product.promotions.length) {
      result.push({
        code: "Sale",
        name: "Sale",
        description: "Sale",
        id: 0,
        type: "PRODUCT CARD",
        value: "Sale",
      });
    }

    return result;
  }, [product.tags]);

  useEffect(() => {
    if (productSendMail) {
      onCLose?.()
    }
  }, [productSendMail])

  const { promotionsHaveConfig } = useDealthom(variantActive);
  const promotionsCode = promotionsHaveConfig.filter((pro) => pro.config?.code);
  return (

    <LoadingElement
      loading={isLoading}
      fallback={<ProductPopupLoading />}
      errors={error?.message ? [error.message] : undefined}
      onReload={refetch}
      className=" min-h-[300px]"
    >
      <>
        <div className=" hidden md:flex flex-col md:flex-row gap-4 bg-white rounded-t-[8px] md:rounded-[8px] max-h-[85vh] h-auto ">
          {/* Photo & Rate */}
          <div className="w-[85px] md:w-[240px] flex flex-row md:flex-col md:items-center gap-2">
            <div className="min-w-[85px] md:min-w-[240px] h-[85px] md:h-[240px] flex items-center justify-center relative">
              <ImageV2
                alt="img"
                width={240}
                height={240}
                src={variantActive.id > 0 ? variantActive?.images[0]?.url : ""}
                layout="responsive"
                loading="eager"
                placeholder={"blur"}
                blurDataURL={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABSSURBVHgBrZCxCoBADENzItcv6NYP96Z+YLcupbcoCE4OVjFLMjwCSYuIHQUtKOo7OLYBVX0GO3XMnDewVcesV3B3ZObpIgIzAxGBmd81/n/PAXpnHmcTvpaoAAAAAElFTkSuQmCC"
                }
              />

              <div className="absolute top-0 left-0 z-10">
                <ProductTester tags={variantActive.tags} />
              </div>
            </div>
            <div className="flex justify-center items-center gap-2">
              <ProductRating
                defaultRating={variantActive.rate}
                item_id={variantActive.id}
              />
              {/* <HeartIcon /> {variantActive.rate.count_rate} yêu thích */}
            </div>

            {listTag.length > 0 && (
              <div className="flex justify-center  divide-x-2">
                {listTag.map((t, index) => (
                  <ProductCardTag
                    key={index}
                    tag={t}
                    className="text-xs  leading-3 font-bold text-gray-500 px-1 border-gray-400"
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col">
            {/* Tags */}
            <div className="flex items-center gap-1 p-1 min-h-[24px]">
              <ProductTags
                tags={variantActive.tags}
                type="PRODUCT GENDER"
                className="mb-2"
              />
            </div>
            {/* Info */}
            <div className="flex flex-col gap-2">
              <h1 className="text-lg text-gray-500 font-bold min-h-[20px]">
                {variantActive.name}
              </h1>
              {variantActive.brand ? (
                <p className="text-base min-h-[17px]">
                  {t(translate.brand)}:{" "}
                  <LinkElement
                    href={`/blogs/thuong-hieu-nuoc-hoa/${variantActive.brand?.handle}`}
                    className="text-base font-bold hover:text-red-500 transition-all"
                  >
                    {variantActive.brand?.title}
                  </LinkElement>{" "}
                </p>
              ) : null}
              <p className="text-base min-h-[17px]">
                {variantActive.option_name
                  ? variantActive.option_name
                  : variantActive.full_name}
              </p>
              <p className="text-base text-gray-400 min-h-[17px]">
                {t(translate.product_sku)}: {variantActive.sku}
              </p>
              {variantActive.summary && (
                <p className="text-sm leading-[1.4] min-h-[16px] line-clamp-1">
                  {variantActive.summary}
                </p>
              )}

              <LinkElement
                href={`/products/${variantActive.handle}`}
                className="block w-fit ml-auto text-sm text-[#004B8F] hover:text-red-500 min-h-[16px]"
              >
                {t(translate.view_detail)}
              </LinkElement>

              <div className=" md:mb-10 md:mt-4   max-h-[246px] overflow-auto scrollbarCustomer">
                <ProductVariantGroup
                  loading={isLoading}
                  variantActive={variantActive}
                  variantFromTags={variantFromTags}
                  handleActiveVariant={handlePopupActiveVariant}
                  isShowImage={false}
                />
              </div>

              <div className="hidden md:flex justify-between">
                <>
                  <div className="flex-1 flex-col gap-1 items-center">
                    {variantActive.id > 0 && (
                      <>
                        {isLoading ? (
                          <div className=" flex flex-col gap-2">
                            <Skeleton className=" h-8  w-40" />
                            <Skeleton className=" h-4  w-32" />
                          </div>
                        ) : (
                          <>
                            <ProductSinglePrice
                              productVariant={variantActive}
                              showDiscount
                              className="flex-col-reverse gap-0 "
                              classNamePrice="text-lg leading-[1.25]"
                              classNamePriceCompare="text-sm leading-[1.6]"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="min-w-[158px] w-[158px] flex flex-col gap-2 items-center">
                    <ShoppingBtn
                      product={variantActive}
                      quantity={quantity}
                      isLoading={isLoading}
                      promotions={
                        variantActive.promotions && variantActive.promotions
                      }
                      onAddQuantity={() => {
                        handleQuantity({ type: "add" });
                      }}
                      onSubQuantity={() => {
                        handleQuantity({ type: "minus" });
                      }}
                      setQuantity={(quan) => {
                        if (quan === 0) {
                          return;
                        }

                        setQuantity(quan);
                      }}
                      className="gap-2"
                      classNameQuantity="mb-1"
                      isCheckInCart={false}
                      isShowPopupError={false}
                    />
                    {/* <ButtonCompares variant={variantActive} /> */}
                    <p className="text-sm text-gray-500 mt-1">
                      <b>{t(translate.free_ship)}</b> {t(translate.all_order)}
                    </p>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        <div className=" md:hidden min-h-[300px]  max-h-[571px] overflow-auto flex flex-col justify-between pb-[40px] mb-[39px]">
          <div>
            <div className=" flex gap-4 max-w-[90%]">
              <div className=" w-[85px] h-[85px] flex-shrink-0">
                <ImageV2
                  alt="img"
                  width={100}
                  height={100}
                  src={
                    variantActive.id > 0 ? variantActive?.images[0]?.url : ""
                  }
                  className=" w-[85px] h-[85px] object-cover"
                  placeholder={"blur"}
                  blurDataURL={
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABSSURBVHgBrZCxCoBADENzItcv6NYP96Z+YLcupbcoCE4OVjFLMjwCSYuIHQUtKOo7OLYBVX0GO3XMnDewVcesV3B3ZObpIgIzAxGBmd81/n/PAXpnHmcTvpaoAAAAAElFTkSuQmCC"
                  }
                />
              </div>
              {variantActive.id > 0 && (
                <div>
                  <ProductTags
                    tags={variantActive.tags}
                    type="PRODUCT GENDER"
                  />

                  <h2 className=" font-semibold text-base line-clamp-2">
                    <LinkElement
                      href={`/products/${variantActive.handle}`}>
                      {variantActive.name}
                    </LinkElement>
                  </h2>
                  <p className=" text-sm">{variantActive.option_name}</p>
                  {/* <ProductSinglePrice
									productVariant={variantActive}
									showDiscount
									className="flex-col-reverse gap-0"
									classNamePrice="text-lg leading-[1.25]"
									classNamePriceCompare="text-sm leading-[1.6]"
									/> */}
                </div>
              )}
            </div>

            <div className=" mt-6">
              <ProductVariantGroup
                showPromotion={false}
                classNameList=" gap-3 flex flex-wrap "
                classNameTag="font-medium"
                loading={isLoading}
                variantActive={variantActive}
                variantFromTags={variantFromTags}
                handleActiveVariant={handleActiveVariant}
                isShowImage={false}
                showOnlySize
                classNameItem="py-1 h-[23px] w-fit  mb-0"
              />
            </div>
          </div>

          <div className=" mt-10">
            <div className=" flex justify-between  w-full">
              <div className=" w-[55%]  flex-shrink-0">
                <ProductSinglePrice
                  productVariant={variantActive}
                  showDiscount
                  className="  flex-row-reverse  items-center gap-2  flex-wrap-reverse   justify-end"
                  classNamePrice="text-lg leading-[1.25]"
                  classNamePriceCompare="text-sm leading-[1.6]"
                  text="-"
                  showRoundBrackets
                  classNameDiscount=" text-red-500"
                />
                <div>
                  {promotionsCode.length ? (
                    <div>
                      {promotionsCode.map((pro) => {
                        return (
                          <>
                            <p className="   text-gray-400">Giảm thêm {pro.discount_value}%</p>
                            <p className="  text-gray-400">Nhập mã: {pro.config?.code}</p>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className=" w-[42%]">
                <ShoppingBtn
                  isShowBuyNow={false}
                  product={variantActive}
                  quantity={quantity}
                  isLoading={isLoading}
                  promotions={
                    variantActive.promotions && variantActive.promotions
                  }
                  onAddQuantity={() => {
                    handleQuantity({ type: "add" });
                  }}
                  onSubQuantity={() => {
                    handleQuantity({ type: "minus" });
                  }}
                  setQuantity={(quan) => {
                    if (quan === 0) {
                      return;
                    }

                    setQuantity(quan);
                  }}
                  handleAddCartSuccecss={handleAddCartSuccecss}
                  className="gap-2"
                  classNameQuantity="mb-1"
                  isCheckInCart={false}
                  isShowPopupError={false}

                />
              </div>
            </div>
            {/* <div className=" h-[50px] bg-white  py-2 shadow-sm  fon ">
              <div
                className={`flex rounded h-full ${
                  !isOutOfStock && "border border-red-500"
                }`}
              >
                {isOutOfStock ? (
                  <ProductBtnSendMail
                    productVariantActive={product}
                    className="w-full"
                  />
                ) : (
                  <>
                    <ProductSelectQuantity
                      quantity={quantity}
                      onChangeQuantity={(quan) => setQuantity(quan)}
                      max={variantActive.limit_sale}
                      maxValid={
                        variantActive.actuals?.actual_quantity ||
                        variantActive.quantity
                      }
                      //   maxValid={5}
                    />
                    <AddToCartBtn
                      productData={variantActive}
                      item_quantity={quantity}
                      isCheckInCart={true}
                      store_id={store_id}
                      className="flex-1 h-full min-h-full border-0 bg-white rounded-none text-red-500 flex items-center justify-center text-sm font-medium"
                      promotions={
                        variantActive.promotions && variantActive.promotions
                      }
                    />
                    <AddToCartBtn
                      productData={variantActive}
                      item_quantity={quantity}
                      store_id={store_id}
                      mode="buy"
                      promotions={
                        variantActive.promotions && variantActive.promotions
                      }
                      className="flex-1 h-full min-h-full bg-red-500 rounded-none text-white flex items-center justify-center text-sm font-medium"
                    />
                  </>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </>
    </LoadingElement>
  );
}
