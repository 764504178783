"use client";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "@/redux/hook";

import { PromotionJson } from "@/types/Promotion.type";
import { ProductAllowSale, ProductJson } from "@/types/Product.type";

import QuantityBtn, { QuantityError } from "./QuantityBtn";
import AddToCartBtn from "~components/cart/AddToCartBtn";
import StopBusiness from "~components/process/StopBusiness";
import ProductBtnSendMail from "~components/product/ProductBtnSendMail";
import LoadingTinySkeleton from "~components/skeleton/LoadingTinySkeleton";
import { cn } from "@/utils/utils";
import PopupDefault from "@/components/PopupDefault";
import { PopupErrorIcon } from "@/components/icons";
import Error from "@/common/api/Error";
import Helper from "@/utils/helper";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import useStoreId from "@/lib/hooks/useStoreId";

type Props = {
	product: ProductJson;
	quantity: number;
	onSubQuantity: () => void;
	onAddQuantity: () => void;
	isCheckInCart?: boolean;
	isLoading?: boolean;
	promotions?: PromotionJson[];
	className?: string;
	classNameQuantity?: string;
	modeShowError?: "popup" | "text";
	isShowPopupError?: boolean;
	setQuantity?: (quantity: number) => void;
	isShowBuyNow?:boolean
	handleAddCartSuccecss?:()=> void
};

const translate = {
	product_quatity_stock: "product_quatity_stock",
	product: "product",
	product_notice: "product_notice",
	product_error_over_quantity: "product_error_over_quantity",
};
export default function ShoppingBtn({
	product,
	quantity,
	promotions = [],
	isLoading,
	isCheckInCart = false,
	onSubQuantity,
	onAddQuantity,
	className,
	classNameQuantity,
	modeShowError,
	isShowPopupError = true,
	setQuantity,
	isShowBuyNow  = true,
	handleAddCartSuccecss
}: Props) {
	const { data: store_id } = useStoreId();

	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);
	const defaultOutStock = product.quantity === 0;

	const { t } = useTranslationApi(Object.values(translate));

	// state
	const [isOutOfStock, setIsOutOfStock] = useState(defaultOutStock);
	const [openPopupError, setPpenPopupError] = useState(false);

	const [openPopupErrorLimit, setOpenPopupErrorLimit] = useState(false);
	// const [quantityCheckError, setQuantityCheckError] = useState(quantity);
	const [isClickAdd, setIsClickAdd] = useState(false);
	const [errorQuatity, setErrorQuatity] = useState<QuantityError | null>(null);

	const [stockReal, setStockReal] = useState(product.quantity);

	const [limitSaleReal, setLimitSaleReal] = useState(product.limit_sale);
	const [isClicked, setIsClicked] = useState(false);

	const handleSubQuantity = () => {
		// setQuantityCheckError((prev) => prev - 1);
		onSubQuantity();
	};

	const handleAddQuantity = () => {
		// !isClickAdd && setIsClickAdd(true);
		// setQuantityCheckError((prev) => prev + 1);
		onAddQuantity();
		setIsClicked(true);
	};

	const handleSetQuantity = (q: number) => {
		setQuantity && setQuantity(q);
	};

	const handleAddItemError = (error: Error) => {
		if (error.errors.includes("error_item_quantity_invalid")) {
			const err: any = Helper.getDataErrorDetail(
				error,
				"error_item_quantity_invalid_detail"
			);
			let stock = err && err.length > 0 && err[0]?.item_quantity;
			if (!stock) {
				stock = 0;
			}

			setErrorQuatity({ type: "out", quantity: stock });
			if (isCheckInCart) {
				const itemThisInCart = cartDetail?.details.data.find((item) => {
					return item.product_id === product.id;
				});

				if (itemThisInCart) {
					if (stock === 0 || stock <= itemThisInCart.item_quantity) {
						setQuantity?.(0);
						setIsOutOfStock(true);
						return;
					}
					setStockReal(stock - itemThisInCart.item_quantity);
				}
			} else {
				setStockReal(stock);
			}
			setQuantity?.(stock);
			return;
		}
	};

	//////////////////////////
	useEffect(() => {
		if (product.quantity <= 0) {
			setIsOutOfStock(true);
		} else {
			setIsOutOfStock(false);
		}
	}, [product, stockReal]);

	useEffect(() => {
		const itemThisInCart = cartDetail?.details.data.find((item) => {
			return item.product_id === product.id;
		});

		if (!isCheckInCart || !itemThisInCart) {
			// setStockReal(0);
			setStockReal(product.quantity);
			setLimitSaleReal(product.limit_sale);
			return;
		}
		setStockReal(product.quantity - itemThisInCart.item_quantity);
		setLimitSaleReal(product.limit_sale - itemThisInCart.item_quantity);
	}, [isCheckInCart, cartDetail?.details.data, product.id]);

	useEffect(() => {
		if (quantity >= stockReal) {
			setErrorQuatity({ type: "out", quantity: stockReal });
			// isClicked && setPpenPopupError(true);
		} else if (quantity > product.limit_sale) {
			setErrorQuatity({ type: "insuff", quantity: product.limit_sale });
			isClicked && setOpenPopupErrorLimit(true);
		} else {
			setErrorQuatity(null);
			setPpenPopupError(false);
			setOpenPopupErrorLimit(false);
		}
		return;
	}, [quantity, stockReal, isClicked]);

	if (isLoading) {
		return <LoadingTinySkeleton />;
	}
	if (product.allow_sale === ProductAllowSale.NOT_ALLOW_SALE) {
		return <StopBusiness />;
	}
	return (
		<div className="relative w-full">
			{product.id === 0 || isOutOfStock ? (
				<ProductBtnSendMail productVariantActive={product} className="w-full" />
			) : (
				<div className={cn("w-full flex flex-col gap-[10px]", className)}>
					<div
						className={cn(
							"flex flex-col items-center justify-between gap-2",
							classNameQuantity
						)}>
						{!isShowPopupError &&
							errorQuatity &&
							errorQuatity.type === "out" && (
								<div>
									<p className="text-red-500 text-sm">
										{t(translate.product_quatity_stock)}{" "}
										{errorQuatity.quantity <= 0 ? 0 : errorQuatity.quantity}{" "}
										{t(translate.product)}
									</p>
								</div>
							)}

						{!isShowPopupError &&
							errorQuatity &&
							errorQuatity.type === "insuff" && (
								<div>
									<p className="text-red-500 text-sm">
										Chỉ được mua tối đa {product.limit_sale}{" "}
										{t(translate.product)}
									</p>
								</div>
							)}

						<QuantityBtn
							onAddQuantity={handleAddQuantity}
							quantity={quantity}
							onSubQuantity={handleSubQuantity}
							product={product}
							className={classNameQuantity}
							isCheckInCart={isCheckInCart}
						/>
					</div>
					<AddToCartBtn
						productData={product}
						item_quantity={quantity}
						handleSetQuantity={handleSetQuantity}
						isCheckInCart={true}
						store_id={store_id}
						mode="add"
						// disabled={stockReal<=0}
						promotions={promotions}
						handleErrors={handleAddItemError}
						handleSuccess={handleAddCartSuccecss}
					/>

					{isShowBuyNow ? <AddToCartBtn
						productData={product}
						item_quantity={quantity}
						store_id={store_id}
						mode="buy"
						promotions={promotions}
					/> : null }
					
				</div>
			)}

			<PopupDefault
				classNameBody="md:max-w-[360px]"
				open={isShowPopupError && openPopupError}
				onClose={() => {
					setQuantity?.(stockReal);
					setPpenPopupError(false);
				}}>
				<div className="h-[143px] flex flex-col gap-4 justify-between items-center">
					<PopupErrorIcon></PopupErrorIcon>
					<p className="font-bold text-gray-500">
						{t(translate.product_notice)}
					</p>
					<p className="text-gray-500">
						{t(translate.product_error_over_quantity)}
					</p>
				</div>
			</PopupDefault>

			<PopupDefault
				classNameBody="md:max-w-[360px]"
				open={isShowPopupError && openPopupErrorLimit}
				onClose={() => {
					setOpenPopupErrorLimit(false);
				}}>
				<div className="h-[143px] flex flex-col gap-4 justify-between items-center">
					<PopupErrorIcon></PopupErrorIcon>
					<p className="font-bold text-gray-500">
						{t(translate.product_notice)}
					</p>
					<p className="text-gray-500">
						{t(translate.product_error_over_quantity)}
					</p>
				</div>
			</PopupDefault>
		</div>
	);
}
