"use client";

import React from "react";
import PhotoIcon from "@/components/icons/PhotoIcon";
import StarFilledIcon from "@/components/icons/StarFilledIcon";
import CircleLoading from "@/components/loadings/CircleLoading";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

const ProductPopupLoading = () => {
  const { t } = useTranslationApi(["loading"]);
  return (
    // <>
    // 	<div
    // 		id="productPopupLoading"
    // 		className="flex flex-col md:flex-row gap-4 bg-white px-[16px] pt-[18px] pb-[68px] md:p-6 md:pb-12 rounded-t-[8px] md:rounded-[8px] min-h-[85vh] md:min-h-[480px] md:h-fit relative">
    // 		{/* Photo & Rate */}
    // 		<div className="flex flex-row md:flex-col md:items-center gap-4 mb-6 md:mb-0 animate-pulse">
    // 			<div className="w-[85px] h-[85px] md:w-[240px] md:h-[240px] bg-gray-300 rounded flex items-center justify-center ">
    // 				<PhotoIcon />
    // 			</div>
    // 			<div className="block md:hidden">
    // 				<p className="w-[90px] h-[10px] bg-gray-400 opacity-70 rounded-full mb-2"></p>
    // 				<p className="w-[120px] h-[6px] bg-gray-300 rounded-full"></p>
    // 			</div>
    // 			<div className="hidden md:flex justify-center items-center gap-2 ">
    // 				{Array(5)
    // 					.fill(0)
    // 					.map((_, index) => {
    // 						return (
    // 							<StarFilledIcon
    // 								key={index}
    // 								className="w-[10px] h-[10px] min-w-[10px] text-gray-300"
    // 							/>
    // 						);
    // 					})}
    // 				<p className="w-20 h-[10px] bg-gray-400 opacity-70 rounded-full"></p>
    // 			</div>
    // 		</div>
    // 		<div className="flex-1 flex flex-col md:max-w-[500px] animate-pulse">
    // 			{/* Tags */}
    // 			<div className="hidden md:flex items-center gap-1 p-1 min-h-[24px]">
    // 				{Array(2)
    // 					.fill(0)
    // 					.map((_, index) => {
    // 						return (
    // 							<span
    // 								key={index}
    // 								className="bg-gray-300 text-gray-500 w-[50px] h-[14px] rounded-sm"></span>
    // 						);
    // 					})}
    // 			</div>
    // 			{/* Info */}
    // 			<div className="hidden md:flex flex-col gap-2">
    // 				<h1 className="bg-gray-400 opacity-70 w-[300px] h-[12px] rounded-full"></h1>
    // 				<p className="w-[140px] h-[6px] bg-gray-300 rounded-full"></p>
    // 				<p className="w-[200px] h-[6px] bg-gray-300 rounded-full"></p>
    // 				<p className="w-[130px] h-[6px] bg-gray-300 rounded-full opacity-70"></p>
    // 				<p className="w-full h-[6px] bg-gray-300 rounded-full"></p>
    // 				<p className="w-[40%] h-[6px] bg-gray-300 rounded-full"></p>
    // 				<p className="block w-[70px] bg-gray-400 opacity-70 ml-auto text h-[6px] rounded-full"></p>
    // 			</div>
    // 			{/* Size */}
    // 			{/* <p className="mt-4 mb-[6px] text-lg text-gray-500 min-h-[19px] leading-[19px]">
    //         Chọn size
    //       </p> */}
    // 			{Array(2)
    // 				.fill(0)
    // 				.map((_, index) => (
    // 					<div key={index}>
    // 						<p className="w-[100px] h-[6px] rounded-full bg-gray-400 opacity-70 mt-6 mb-2"></p>
    // 						<ul className={"grid grid-cols-2 lg:grid-cols-3 gap-2 "}>
    // 							<li className="h-[44px] rounded bg-gray-200 border border-gray-300 px-3 flex flex-col gap-1 justify-center ">
    // 								<p className="w-full h-1 rounded-full bg-gray-300"></p>
    // 								<p className="w-[30%] h-1 rounded-full bg-gray-300"></p>
    // 							</li>
    // 							<li className="h-[44px] rounded bg-gray-200 border border-gray-300 px-3 flex flex-col gap-1 justify-center ">
    // 								<p className="w-full h-1 rounded-full bg-gray-300"></p>
    // 								<p className="w-[30%] h-1 rounded-full bg-gray-300"></p>
    // 							</li>
    // 							<li className="h-[44px] rounded bg-gray-200 border border-gray-300 px-3 flex flex-col gap-1 justify-center ">
    // 								<p className="w-full h-1 rounded-full bg-gray-300"></p>
    // 								<p className="w-[30%] h-1 rounded-full bg-gray-300"></p>
    // 							</li>
    // 						</ul>
    // 					</div>
    // 				))}
    // 			{/* Actions Desktop */}
    // 			<div className="mt-10 hidden md:flex justify-between gap-10">
    // 				<div className="flex-1 flex-col gap-1 items-center">
    // 					<p className="bg-gray-400 opacity-70 w-[90px] h-[10px] rounded-full mb-2"></p>
    // 					<p className="bg-gray-300 w-[160px] h-[6px] rounded-full"></p>
    // 				</div>
    // 				<div className="w-[158px] flex flex-col gap-2 items-center">
    // 					<p className="bg-gray-300 w-full h-[34px] rounded"></p>
    // 					<p className="bg-gray-300 w-full h-[34px] rounded"></p>
    // 					<p className="bg-gray-400 w-full h-[8px] opacity-70 rounded"></p>
    // 				</div>
    // 			</div>
    // 			{/* Quantity Mobile */}
    // 			<div className="flex md:hidden items-center justify-between gap-4 mt-4">
    // 				<p className="bg-gray-300 w-10 h-[10px] rounded-full"></p>
    // 				<div className="flex items-center gap-2 w-fit">
    // 					<span className="h-[14px] w-[14px] bg-gray-300 rounded-sm"></span>
    // 					<span className="h-[6px] w-5 bg-gray-300 rounded-full"></span>
    // 					<span className="h-[14px] w-[14px] bg-gray-300 rounded-sm"></span>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	</div>
    // 	{/* Actions Mobile */}
    // 	<div className="sticky md:hidden bottom-0 left-0 right-0 px-4 py-2 bg-white">
    // 		<div className="border border-gray-300 grid grid-cols-10 gap-[1px] rounded overflow-hidden">
    // 			<div className="col-span-2 relative">
    // 				<p className="absolute text-sm text-gray-500 w-full h-full p-2 flex items-center justify-center gap-2">
    // 					<span className="h-[6px] w-5 bg-gray-300 rounded-full"></span>
    // 					<span className="h-[14px] w-[14px] bg-gray-300 rounded-sm"></span>
    // 				</p>
    // 			</div>
    // 			<div className="col-span-4">
    // 				<p className="bg-gray-300 w-full h-[34px]"></p>
    // 			</div>
    // 			<div className="col-span-4">
    // 				<p className="bg-gray-300 w-full h-[34px]"></p>
    // 			</div>
    // 		</div>
    // 	</div>
    //</>
    <div className="absolute inset-0 flex justify-center items-center gap-2">
      <CircleLoading />
      <span>{t("loading")}</span>
    </div>
  );
};

export default ProductPopupLoading;
