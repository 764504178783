"use client";

import React, { useState } from "react";
import useTranslationApi from "@/lib/hooks/useTranslationApi";

import { ProductTagJson, ProductTesterValue } from "@/types/Product.type";

import Tabs, { Tab } from "@/components/Tabs";
import PopupDefault from "@/components/PopupDefault";
import { cn } from "@/utils/utils";

const translate = {};

export default function ProductTester({
  tags,
  className,
}: {
  tags: ProductTagJson[];
  className?: string;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslationApi([]);
  const tagsShow = tags.filter(
    (item) =>
      item.type === "PRODUCT TESTER" && item.value !== ProductTesterValue.full
  );

  const isShow = tagsShow.length > 0 ? true : false;

  let indexActive = 0;
  if (tagsShow.length === 1 && tagsShow[0].value === ProductTesterValue.unbox) {
    indexActive = 1;
  }

  const tabs: Tab[] = [
    {
      headTitle: (
        <p className="text-base leading-[1.6] pb-[14px] hidden md:block">
          Nước hoa <span className="underline underline-offset-2">Tester</span>{" "}
          là gì ?
        </p>
      ),
      title: "Tester là gì ?",
      content: (
        <div className="text-base leading-[1.6] text-gray-500 flex flex-col gap-5">
          <p>
            Nước hoa Tester là hàng mới và chưa từng được sử dụng, là nước hoa
            chính hãng, authentic 100%, có mùi hương và kiểu dáng giống chai mới
            full seal.
          </p>
          <p>
            Nước hoa Tester được đựng trong những hộp giấy đơn giản. Chai sản
            phẩm Tester có thể có nắp đi kèm hoặc không có nắp. Nước hoa Tester
            được đựng trong box đơn giản là để giảm giá thành sản phẩm.
          </p>
          <p>
            Bạn có thể sẽ tìm thấy dòng chữ Tester hay Testeur (tiếng Pháp) in
            trên vỏ hộp hoặc thậm chí in trên chai nước hoa. Một số chai còn ghi
            rõ dòng chữ Not for Sale (không phải sản phẩm để bán) in trên hộp
            hoặc chính chai nước hoa đó.
          </p>
        </div>
      ),
      className:
        "rounded text-sm leading-[1.6] h-[34px] text-gray-500 border border-gray-300 px-[14px] py-2 font-normal hover:border hover:border-gray-300 hover:bg-gray-300 transition-all",
      classActive:
        "rounded text-sm leading-[1.6] h-[34px] border px-[14px] py-2 border-red-500 text-red-500 hover:border hover:border-red-300 hover:bg-white transition-all",
    },
    {
      headTitle: (
        <p className="text-base leading-[1.6] pb-[14px] hidden md:block">
          Nước hoa <span className="underline underline-offset-2">Unbox</span>{" "}
          là gì ?
        </p>
      ),
      title: "Unbox là gì ?",
      content: (
        <div className="text-base leading-[1.6] text-gray-500 flex flex-col gap-5">
          <p>
            Nước hoa Unbox (không hộp) là nước hoa chính hãng, authentic và mới
            100%. Đôi khi hộp bên ngoài bị hỏng hoặc bị rách seal ni-lon do quá
            trình vận chuyển nên được tháo bỏ. Có cùng mùi hương và kiểu dáng
            giống chai mới full seal.
          </p>
          <p>Mua nước hoa unbox cũng là một cách để tiết kiệm.</p>
        </div>
      ),
      className:
        "rounded text-sm leading-[1.6] h-[34px] text-gray-500 border border-gray-300 px-[14px] py-2 font-normal hover:border hover:border-gray-300 hover:bg-gray-300 transition-all",
      classActive:
        "rounded text-sm leading-[1.6] h-[34px] border px-[14px] py-2 border-red-500 text-red-500 hover:border hover:border-red-300 hover:bg-white transition-all",
    },
  ];

  return (
    <>
      {isShow && (
        <>
          <div
            className={cn(
              "w-[60px] h-[60px] bg-gray-300 rounded-full flex justify-center items-center p-[5px] cursor-pointer text-sm text-gray-500",
              className
            )}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            {indexActive === 0 ? (
              <span>
                Tester <br /> là gì?
              </span>
            ) : (
              <span>
                Unbox <br /> là gì?
              </span>
            )}
          </div>

          <PopupDefault
            open={open}
            onClose={() => setOpen(false)}
            classNameBody="w-full md:max-w-[600px] h-[70vh] md:h-[50vh] rounded-[8px]"
            isDrawerMobile
          >
            <Tabs
              className="gap-2 mt-4 md:mt-0 justify-center md:justify-start pb-[14px] mb-[14px] border-gray-300"
              defaultlIndex={indexActive}
              tabs={tabs}
              showHeadTitle={true}
              classNameBody="px-2"
            />
          </PopupDefault>
        </>
      )}
    </>
  );
}
