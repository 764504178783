"use client";

import { cn } from "@/utils/utils";
import Button from "@/components/ui/Button";

type Props = {
	label?: React.ReactNode;
	onClick?: () => void;
	className?: String;
};
export default function StopBusiness({ label, onClick, className }: Props) {
	return (
		<div className={cn("uppercase", className)} onClick={onClick}>
			{label ? (
				className
			) : (
				<Button className="uppercase" mode="dark">
					ngừng kinh doanh
				</Button>
			)}
		</div>
	);
}
