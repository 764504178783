"use client";
import Error from "@/common/api/Error";
import Cart from "@/common/constants/cart";
import PopupDefault from "@/components/PopupDefault";
import PopupV2 from "@/components/PopupV2";
import { PopupErrorIcon } from "@/components/icons";
import Button from "@/components/ui/Button";
import useCartV2 from "@/lib/hooks/useCartV2";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { getQuantityRemaining } from "@/lib/repo/cart";
import {
	setCartGlobalErrors,
	setProductByNow,
	setRecentProduct,
} from "@/redux/features/CartSlice";
import { setPopupId } from "@/redux/features/ShopSlice";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { PricePromotionJson } from "@/types/Cart.type";
import { ProductJson } from "@/types/Product.type";
import { PromotionJson } from "@/types/Promotion.type";
import { cn } from "@/utils/utils";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { useDebounce, useDebouncedCallback } from "use-debounce";

type Props = {
	productData: ProductJson;
	item_quantity: number;
	store_id: number;
	promotions?: PromotionJson[];
	handleErrors?: (errors: Error) => void;
	handleSuccess?: () => void;
	handleSetQuantity?: (q: number) => void;
	mode?: "add" | "buy";
	className?: string;
	disabled?: boolean;
	isCheckInCart?: boolean;
	label?: React.ReactNode;
	// idPopupSuccsec? :string
};

type Add = "add" | "buy_now";
type OpenErrorStock = {
	open: boolean;
	type: Add;
};

const translate = {
	product_buy_now: "product_buy_now",
	product_add_to_cart: "product_add_to_cart",
	product_notice: "product_notice",
	product_error_over_quantity: "product_error_over_quantity",
	go_to_cart: "go_to_cart",
	confirm: "confirm",
	close: "close",
	no: "cart_no",
	yes: "cart_yes",
};

const AddToCartBtn = ({
	productData,
	item_quantity,
	store_id,
	promotions = [],
	handleErrors,
	handleSuccess,
	handleSetQuantity,
	mode = "add",
	className,
	disabled,
	isCheckInCart = false,
	label,
	// idPopupSuccsec
}: Props) => {
	const promotionsItems = promotions;
	const router = useRouter();
	const cartDetail = useAppSelector((state) => state.CartReducer.cartDetail);
	const { t } = useTranslationApi(Object.values(translate));
	const pathName = usePathname();
	const dispatch = useAppDispatch();
	const { addItemToCart, buyNow } = useCartV2({});
	//state
	const [loading, setLoading] = useState<boolean>(false);
	console.log("🚀 ~ loading:", loading);
	const [alreadyStock, setAlreadyStock] = useState<number>(0);
	const [openErrorStock, setOpenErrorStock] = useState<OpenErrorStock>({
		open: false,
		type: "add",
	});

	const pricePreivew = useMemo(() => {
		let result: PricePromotionJson = {
			discount: 0,
			item_quantity: 0,
			price_final: 0,
			price_Unit: 0,
			product_id: 0,
		};
		if (!productData) return result;
		if (productData.pricePreview && productData.pricePreview.discount > 0) {
			result = productData.pricePreview;
		}
		return result;
	}, [productData]);

	const checkBeforeAdd = (type: Add): boolean => {
		let alreadyQuantity = 0;

		const checkQuantity = (alreadyQuantity: number) => {
			const alQuantity = alreadyQuantity;
			if (
				alQuantity + item_quantity > productData.quantity ||
				alQuantity + item_quantity > productData.limit_sale
			) {
				//show error
				setOpenErrorStock({ open: true, type: type });
				return false;
			} else {
				return true;
			}
		};
		if (isCheckInCart) {
			const itemThisInCart = cartDetail?.details.data.find((item) => {
				return item.product_id === productData.id;
			});

			if (itemThisInCart) {
				alreadyQuantity = itemThisInCart.item_quantity;
				return checkQuantity(alreadyQuantity);
			} else {
				return checkQuantity(0);
			}
		} else {
			return checkQuantity(0);
		}
	};

	const handleAddToCart = useDebouncedCallback(() => {
		setLoading(true);

		addToCart().then((res) => {
			if (!["buy_success"].includes(res)) {
				setLoading(false);
			}
		});
	}, 1000);

	const addToCart = async (): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			if (mode === "add") {
				if (!checkBeforeAdd("add")) {
					resolve("check_fail");
					return;
				}

				await addItemToCart(
					productData,
					item_quantity,
					promotionsItems,
					() => {
						//fix logic price add to cart
						let result = productData.price;

						if (pricePreivew.discount > 0) {
							result = pricePreivew.price_final;
						}
						handleSuccess?.()
						dispatch(
							setRecentProduct([
								{
									...productData,
									quantity: item_quantity,
									price: result * item_quantity,
								},
							])
						);
						dispatch(setPopupId(Cart.POPUP.add_cart_popup));
						resolve("add_success");
					},
					(error) => {
						handleErrors && handleErrors(error);
						dispatch(setCartGlobalErrors(error.errors));
						dispatch(setPopupId(Cart.POPUP.cart_error_popup));
						resolve("add_to_cart_fail");
					}
				);
			} else if (mode === "buy") {
				if (!checkBeforeAdd("buy_now")) {
					resolve("check_fail");
					return;
				}
				// dispatch(setProductByNow(productData));
				// router.push(
				// 	`/checkouts/${productData.id}?buynow=1&quantity=${item_quantity}`
				// );

				await buyNow(
					productData,
					item_quantity,
					promotionsItems,
					(orderId) => {
						dispatch(setProductByNow(productData));
						router.push(`/checkouts/${orderId}`);
						resolve("buy_success");
					},
					(error) => {
						handleErrors && handleErrors(error);
						dispatch(setCartGlobalErrors(error.errors));
						dispatch(setPopupId(Cart.POPUP.cart_error_popup));
						resolve("buy_now_fail");
					}
				);
			}
		});
	};

	const handleAddCartWithQuantity = async (quantity: number) => {
		setLoading(true);

		await addItemToCart(
			productData,
			quantity,
			promotionsItems,
			() => {
				setOpenErrorStock((prev) => ({ ...prev, open: false }));
				//fix logic price add to cart
				let result = productData.price;

				if (pricePreivew.discount > 0) {
					result = pricePreivew.price_final;
				}

				dispatch(
					setRecentProduct([
						{
							...productData,
							quantity: quantity,
							price: result * quantity,
						},
					])
				);
				dispatch(setPopupId(Cart.POPUP.add_cart_popup));
			},
			(error) => {
				// setOpenErrorStock((prev) => ({ ...prev, open: true }));
				handleErrors && handleErrors(error);
				dispatch(setCartGlobalErrors(error.errors));
				dispatch(setPopupId(Cart.POPUP.cart_error_popup));
			}
		);
		// setLoading(false);
	};
	/////////////////////////////

	const isDisableStock = useMemo(() => {
		if (
			item_quantity > productData.limit_sale ||
			item_quantity > productData.quantity
		) {
			return true;
		}
		return false;
	}, [productData, item_quantity]);

	const quantityRemainingStatus = useMemo(() => {
		// const stockRemaningAlowSale =
		// 	productData.limit_sale - (item_quantity + alreadyStock);
		// const qAlowSale = productData.allow_sale;
		// if (stockRemaningAlowSale < 0 && qAlowSale - alreadyStock > 0) {
		// 	return qAlowSale - alreadyStock;
		// }
		const dataCheck = getQuantityRemaining({
			quantity: item_quantity,
			actual: productData.quantity,
			alreadyStock: alreadyStock,
			limitSale: productData.limit_sale,
		});

		return dataCheck.status;
	}, [alreadyStock, productData.quantity, item_quantity]);

	const quantityRemaining = useMemo(() => {
		// const stockRemaningAlowSale =
		// 	productData.limit_sale - (item_quantity + alreadyStock);
		// const qAlowSale = productData.allow_sale;
		// if (stockRemaningAlowSale < 0 && qAlowSale - alreadyStock > 0) {
		// 	return qAlowSale - alreadyStock;
		// }
		const dataCheck = getQuantityRemaining({
			quantity: item_quantity,
			actual: productData.quantity,
			alreadyStock: alreadyStock,
			limitSale: productData.limit_sale,
		});

		return dataCheck.allowedValue;
	}, [alreadyStock, productData.quantity, item_quantity]);

	const mesagePopupQuantityError = useMemo(() => {
		// const stockRemaning = productData.quantity - alreadyStock;
		// const stockRemaningAlowSale =
		// 	productData.limit_sale - (item_quantity + alreadyStock);

		const dataCheck = getQuantityRemaining({
			quantity: item_quantity,
			actual: productData.quantity,
			alreadyStock: alreadyStock,
			limitSale: productData.limit_sale,
		});
		const status = dataCheck.status;
		const AlowedValue = dataCheck.allowedValue;
		const typeError = openErrorStock.type;
		if (status === "out_limit_sale") {
			const text = typeError == "add" ? "thêm" : "mua";
			const text1 = typeError == "add" ? "trong giỏ hàng" : "";

			return (
				<>
					Bạn chỉ có thể {text} tối đa <strong>{productData.limit_sale}</strong>{" "}
					sản phẩm {text1}. Vui lòng liên hệ <strong>Hotline <a href="tel:1900 0129">1900 0129</a></strong>{" "}
					nếu như mua nhiều hơn {productData.limit_sale} sản phẩm.
				</>
			);
		}

		if (status === "in_limit_sale") {
			const text = typeError === "buy_now" ? "mua" : "thêm vào giỏ hàng";

			return (
				<>
					Số lượng tối đa có thể {text} là <strong>{AlowedValue}</strong> sản
					phẩm. Vui lòng liên hệ <strong>Hotline <a href="tel:1900 0129">1900 0129</a></strong> nếu như mua
					nhiều hơn {productData.limit_sale} sản phẩm.
				</>
			);
		}

		if (status === "out") {
			const text = typeError === "buy_now" ? "mua" : "thêm";
			const text1 = typeError == "add" ? "trong giỏ hàng" : "";

			return (
				<>
					{typeError === "buy_now" ? (
						<>Số lượng tồn kho chưa thỏa mãn yêu cầu</>
					) : (
						<>
							Bạn chỉ có thể {text} tối đa <strong>{alreadyStock}</strong> sản
							phẩm
						</>
					)}{" "}
					{text1}.
				</>
			);
		}

		if (status === "in") {
			const text = typeError === "buy_now" ? "mua" : "thêm";
			const text1 = typeError == "add" ? "vào giỏ hàng" : "";

			return (
				<>
					Số lượng tối đa có thể {text} {text1} là{" "}
					<strong>{AlowedValue}</strong>.
				</>
			);
		}

		// if (stockRemaningAlowSale < 0) {
		// 	if (productData.limit_sale - alreadyStock <= 0) {
		// 		return `Bạn chỉ có thể thêm tối đa ${productData.limit_sale} sản phẩm trong giỏ hàng. Vui lòng liên hệ Hotline 1900 0129 nếu như mua nhiều hơn ${productData.limit_sale} sản phẩm`;
		// 	}
		// 	return `Số lượng tối đa có thể thêm vào giỏ hàng là ${
		// 		productData.limit_sale - alreadyStock
		// 	} sản phẩm. Vui lòng liên hệ Hotline 1900 0129 nếu như mua nhiều hơn ${
		// 		productData.limit_sale
		// 	} sản phẩm`;
		// } else {
		// 	const quantiyCanBuy =
		// 		stockRemaningAlowSale === 0 ? 1 : stockRemaningAlowSale;
		// 	if (stockRemaning < quantiyCanBuy) {
		// 		if (stockRemaning <= 0) {
		// 			return `Bạn chỉ có thể thêm tối đa ${alreadyStock} sản phẩm trong giỏ hàng`;
		// 		}
		// 		return `Số lượng tối đa có thể thêm vào giỏ hàng là ${stockRemaning}`;
		// 	}
		// 	return `Số lượng tối đa có thể thêm vào giỏ hàng là ${quantiyCanBuy}`;

		// }
	}, [
		alreadyStock,
		productData.quantity,
		openErrorStock.type,
		item_quantity,
		productData.id,
	]);

	useEffect(() => {
		(() => {
			if (isCheckInCart) {
				const itemThisInCart = cartDetail?.details.data.find((item) => {
					return item.product_id === productData.id;
				});
				if (itemThisInCart) {
					setAlreadyStock(itemThisInCart.item_quantity);
				} else {
					setAlreadyStock(0);
				}
			}
		})();
	}, [cartDetail, productData.id, isCheckInCart]);

	useEffect(() => {
		if (pathName.startsWith("/checkouts")) {
			dispatch(setPopupId(""));
		}
	}, [pathName]);

	return (
		<>
			<Button
				disabled={loading || disabled}
				className={cn(
					"w-full disabled:bg-gray-300 disabled:border-none disabled:text-gray-400",
					className
				)}
				loading={loading}
				mode="main"
				label={
					label
						? label
						: mode === "add"
						? t("product_add_to_cart")
						: t("product_buy_now")
				}
				onClick={(e: any) => {
					e.preventDefault();
					setLoading(true);
					handleAddToCart();
				}}
			/>

			<PopupDefault
				classNameBody="rounded-[8px] bg-white  max-w-[330px] max-w-[360px] md:w-[360px] md:min-h-[300px] h-fit px-4 py-6 "
				open={openErrorStock.open}
				// isDrawerMobile
				onClose={() => {
					setOpenErrorStock((prev) => ({ ...prev, open: false }));
				}}>
				<div className="h-fit flex flex-col gap-4 justify-between items-center">
					<PopupErrorIcon />
					<p className="font-bold text-gray-500">
						{t(translate.product_notice)}
					</p>
					<p className="text-gray-500 text-center px-[22px]">
						{mesagePopupQuantityError}
					</p>
					<div className="flex justify-between gap-4">
						{quantityRemaining > 0 && alreadyStock < productData.limit_sale ? (
							<>
								<Button
									mode="dark-outline"
									onClick={() => {
										setOpenErrorStock((prev) => ({ ...prev, open: false }));
									}}
									label={t(translate.no)}
								/>
								{openErrorStock.type === "add" ? (
									<Button
										mode="main"
										loading={loading}
										onClick={() => {
											handleAddCartWithQuantity(quantityRemaining);
										}}
										label={t(translate.yes)}
									/>
								) : quantityRemaining > 0 ? (
									<Button
										mode="main"
										loading={loading}
										onClick={() => {
											setLoading(true);
											dispatch(setProductByNow(productData));
											router.push(
												`/checkouts/${productData.id}?buynow=1&quantity=${quantityRemaining}`
											);
										}}
										label={t(translate.yes)}
									/>
								) : null}
							</>
						) : (
							<>
								{/* <Button
									mode="dark-outline"
									onClick={() => {
										setOpenErrorStock((prev) => ({ ...prev, open: false }));
									}}
									label={t(translate.no)}
									loading={loading}
								/> */}
								{quantityRemainingStatus !== "out_limit_sale" && (
									<Button
										mode="main"
										onClick={() => {
											setLoading(true);
											router.push("/cart");
										}}
										label={t(translate.go_to_cart)}
										loading={loading}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</PopupDefault>
		</>
	);
};

export default AddToCartBtn;
