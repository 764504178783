import { cn } from "@/utils/utils";
import React from "react";

export default function ProductCardCountChilds({
	count,
	className,
}: {
	count: number;
	className?: string;
}) {
	return (
		<p className={cn("h-4 min-h-[16px] text-sm", className)}>
			{count} {count > 1 ? "Sizes" : "Size"}
		</p>
	);
}
