export function getQuantityRemaining({
	quantity,
	actual,
	limitSale,
	alreadyStock,
}: {
	quantity: number;
	actual: number;
	limitSale: number;
	alreadyStock: number;
}): {
	status: "out_limit_sale" | "out" | "in" | "in_limit_sale";
	allowedValue: number;
} {
	const stockRemaningOfLimitSale = limitSale - (quantity + alreadyStock);
	const stockRemaning = actual - alreadyStock;

	if (limitSale < actual) {
		if (stockRemaningOfLimitSale < 0) {
			return {
				status: "out_limit_sale",
				allowedValue: 0,
			};
		} else {
			return {
				status: "in_limit_sale",
				allowedValue: limitSale - alreadyStock,
			};
		}
	} else {
		if (stockRemaning <= 0) {
			return {
				status: "out",
				allowedValue: 0,
			};
		} else {
			return {
				status: "in",
				allowedValue: stockRemaning,
			};
		}
	}

	if (stockRemaningOfLimitSale < 0) {
		// số lượng mua + trong giỏ hàng > số lượng giới hạn mua của product
		if (limitSale - alreadyStock <= 0) {
			// trong giỏ hàng đã có số lượng vượt or = số lượng limit
			return {
				status: "out_limit_sale",
				allowedValue: 0,
			};
		}

		if (stockRemaning <= 0) {
			// Số lượng tồn kho sau khi trừ trong giỏ hàng đã hết hàng (<=0)
			return {
				status: "out",
				allowedValue: 0,
			};
		} else if (stockRemaning < limitSale - alreadyStock) {
			return {
				status: "in",
				allowedValue: stockRemaning,
			};
		} else {
			return {
				status: "in_limit_sale",
				allowedValue: limitSale - alreadyStock,
			};
		}
	} else {
		const stockAlowOfLimitSale = stockRemaningOfLimitSale || 1;

		if (stockRemaning < limitSale) {
			// số lượng còn lại đc phép mua phải <= alreadyStock

			if (stockRemaning <= 0) {
				// Số lượng tồn kho sau khi trừ trong giỏ hàng đã hết hàng (<=0)
				return {
					status: "out",
					allowedValue: 0,
				};
			} else {
				return {
					status: "in",
					allowedValue: stockRemaning,
				};
			}
		} else {
			return {
				status: "in",
				allowedValue: stockAlowOfLimitSale,
			};
		}
	}
}
