"use client";

import { StarIcon } from "@/components/icons";
import { ProductJson } from "@/types/Product.type";
import React, { useEffect, useState } from "react";

export default function ProductCardRateAndSale({
	product,
}: {
	product: ProductJson;
}) {
	return (
		<div className=" flex gap-1 items-center">
			<p className="  inline-flex  items-center  gap-1 text-sm text-gray-400">
				{product.rate.rate || 0} <StarIcon className=" w-3 h-3  text-red-500" />
			</p>
			{/* <span>|</span>
      <span className=' inline-flex  items-center text-sm text-gray-400'>Đã bán {randomNumber}</span> */}
		</div>
	);
}
