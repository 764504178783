"use client";
import PopupV2 from "@/components/PopupV2";
import Button from "@/components/ui/Button";
import { getSettingV2 } from "@/lib/api/shop/setting";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { useAppSelector } from "@/redux/hook";
import { ProductJson } from "@/types/Product.type";
import { cn } from "@/utils/utils";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import ProductPopup from "~components/collection/children/ProductPopup";

export default function ProductCardQuickView({
	product,
	className,
	translateKey,
	mode = "main",
}: {
	product: ProductJson;
	className?: string;
	translateKey?: string;
	mode?: "main" | "main-outline";
}) {
	const [open, setOpen] = useState(false);
	// const { t } = useTranslationApi(["quick_view"]);
	const popupId = useAppSelector((state) => state.ShopReducer.popupId);

	const { data: translate } = useQuery({
		queryKey: [translateKey || "quick_view"],
		queryFn: () => getSettingV2(translateKey || "quick_view"),
		staleTime: 5 * 60 * 1000,
	});

	//handle
	useEffect(() => {
		if (popupId) {
			setOpen(false);
		}
	}, [popupId]);
	return (
		<>
			<Button
				label={translate?.data.value || "..."}
				mode={mode}
				className={cn(
					"text-sm transition-all duration-500 hidden md:flex min-h-[27px] h-[27px] w-full py-0 items-center justify-center",
					className
				)}
				onClick={(e: any) => {
					e.stopPropagation();
					setOpen(true);
				}}
			/>
			<PopupV2 open={open} onOpenChange={() => setOpen(false)} isDrawerMobile>
				<ProductPopup product={product} />
			</PopupV2>
		</>
	);
}
