import ProductCardLink from "./ProductCardLink";
import ProductCardQuickView from "./ProductCardQuickView";
import ProductCardBrand from "./ProductCardBrand";
import ProductCardName from "./ProductCardName";
import ProductCardPrice from "./ProductCardPrice";
import ProductCardCountChilds from "./ProductCardCountChilds";
import ProductCardRateAndSale from "./ProductCardRateAndSale"
import ProductCardTag from "./ProductCardTag"

export {
	ProductCardLink,
	ProductCardQuickView,
	ProductCardBrand,
	ProductCardName,
	ProductCardPrice,
	ProductCardCountChilds,
	ProductCardRateAndSale,
	ProductCardTag
};
